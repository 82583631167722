body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.logo {
    padding: 10px;
    width: 250px;
    height: 64px;
    /* background: rgb(255, 255, 255); */
    display: flex;
    justify-content: center;
    background-color: hsl(209, 100%, 8%);
}

.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout-logo {
    height: 45px;
    width: auto;
}

.card-table {
    padding: 0;
}

/* .site-layout .site-layout-background {
  background: #fff;
} */

/* .samples-layout {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 1200px) {
  .preshipment-layout {
    flex-wrap: wrap;
  }
} */

/* @media only screen and (max-width: 500px) {
  .form-container {
    max-width: 1000px;
    height: 100%;
    position: absolute;
  }
} */
.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: whitesmoke;
}

.form-container {
    overflow: auto;
    padding: 5px;
    height: 100%;
    max-height: 480px;
    width: 100%;
    max-width: 400px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.register-container {
    overflow: auto;
    padding: 5px;
    height: 100%;
    max-height: 800px;
    width: 100%;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-logo {
    margin: 10px;
    width: 90%;
    height: auto;
}

.rcw-conversation-container .rcw-header {
    background-color: #ff9900;
}

.plans-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
}

.dx-master-detail-cell {
    padding: 10px !important;
    overflow-y: scroll;
    background-color: white;
}

.dx-datagrid-rowsview {
    overflow-y: scroll;
}

.ant-comment-content-author-name {
    color: black !important;
    font-weight: 600;
}
.ant-comment-content-author-time {
    color: gray !important;
}

@media only screen and (max-width: 1000px) {
    .plans-container {
        display: flex;
        gap: 20px;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
    }
}
